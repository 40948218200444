import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "@/hooks/useOnClickOutside";
import BoutonLink from "@/ui/boutonLink/index";
import HamburgerMenuButton from "@/ui/hamburgerMenuButton";
import { PUBFORMATS } from "@/ui/pub/formats";
import NavMobile from "../nav/navMobile";
import styles from "./headerMobile.module.scss";
import Image from "next/legacy/image";

import Pub from "@/ui/pub";

const HeaderMobile = () => {
    const headerRef = useRef(null);
    const navContainerRef = useRef(null);
    const [delayedHidePub, setDelayedHidePub] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    useOnClickOutside([navContainerRef, headerRef], () => setIsMenuOpened(false));

    const handleScroll = () => {
        setDelayedHidePub(window.scrollY > 10);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <>
            <div className={classNames([styles.headerWithPub, styles.sticky])}>
                <div
                    className={classNames(styles.pubMobile, {
                        [styles.hideWithDelay]: delayedHidePub && !isMenuOpened,
                        [styles.hideNoDelay]: isMenuOpened,
                    })}
                >
                    <Pub format={PUBFORMATS.leaderboardMobile} />
                </div>

                <div ref={headerRef}>
                    <div className={styles.barMnu}>
                        <div className={styles.menuButton}>
                            <HamburgerMenuButton
                                ariaLabel="navigation menu"
                                ariaExpanded={isMenuOpened}
                                ariaControls="nav-mobile-panel"
                                handleToggleMenu={() => setIsMenuOpened(!isMenuOpened)}
                                isOpened={isMenuOpened}
                            />
                        </div>
                        <div className={styles.logoLink}>
                            <BoutonLink href="/" withStyles={false} arialabel="accueil">
                                <Image
                                    alt="Cuisinez"
                                    src="/images/logo/logo_cuisinez_horizontal.svg"
                                    width="144"
                                    height="39"
                                />
                            </BoutonLink>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ref={navContainerRef}
                className={classNames(styles.navContainer, { [styles.show]: isMenuOpened })}
                style={{
                    top: `${headerRef?.current?.offsetHeight - 6}px`,
                    height: `calc(100vh - ${headerRef?.current?.offsetHeight - 6}px)`,
                }}
            >
                {isMenuOpened && <NavMobile linkClicked={() => setIsMenuOpened(false)} />}
            </div>
        </>
    );
};

export default HeaderMobile;

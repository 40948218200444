import classNames from "classnames";
import React, { useEffect, memo, useState, createRef } from "react";
import { useRouter } from "next/router";
import styles from "./pub.module.scss";
import { PubFormat } from "@/types/components/pub";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    madops: any;
  }
}

type PubProps = { className?: string; format: PubFormat };

const Pub = ({ format }: PubProps) => {
  const router = useRouter();
  const pubRef = createRef<HTMLDivElement>();
  const [pubOptions, setPubOptions] = useState<string | null>(null);
  const pubStyle = setPubStyle();

  function buildOptions() {
    const { sizes, sizeMapping, kv } = format;

    const options = {
      sizes,
      sizeMapping: sizeMapping && [
        { viewport: { min_width: 0, min_height: 0 }, sizes: sizeMapping[0] },
        { viewport: { min_width: 768, min_height: 0 }, sizes: sizeMapping[1] },
      ],
      dfpId: "21658289790,270051662",
      dfpAdUnitPath: `cuisinez/${format.name}-${Math.floor(
        Math.random() * 1000000
      )}`,
      kv: {
        ...kv,
        projet: "cuisinez",
        emission: "",
        section: "",
      },
    };

    const isEpisode = router.asPath.split("/").includes("episode");

    const section = isEpisode
      ? "episode"
      : router.asPath.split("/")[1] || "accueil";
    options.kv.section = section.replace(/s$/, "");

    const isEmission = options.kv.section == "emission" || isEpisode;
    options.kv.emission = isEmission ? router.asPath.split("/")[3] || "" : "";

    return JSON.stringify(options);
  }

  function setPubStyle() {
    const { sizes, sizeMapping } = format;

    if (sizeMapping) {
      return {
        "--pub-height": `${sizeMapping[0][0][1]}px`,
        "--pub-height-desktop": `${sizeMapping[1][0][1]}px`,
      } as React.CSSProperties;
    }

    return {
      "--pub-height": `${sizes[0][1]}px`,
    } as React.CSSProperties;
  }

  useEffect(() => {
    if (!pubRef.current?.dataset["options"]?.length) {
      setPubOptions(buildOptions());
    }
  }, []);

  useEffect(() => {
    const setOptionsOnRouteChange = () => {
      if (!pubRef.current) {
        setPubOptions(buildOptions());
      }
    };
    router.events.on("routeChangeComplete", setOptionsOnRouteChange);
    return () => {
      router.events.off("routeChangeComplete", setOptionsOnRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (pubRef.current?.dataset["options"]?.length) {
      window.madops?.addDynamicAd(pubRef.current);
    }
  }, [pubRef]);

  return (
    <div
      data-component="Pub"
      className={classNames(
        "pub",
        styles.pub,
        styles[format.name],
        format.name
      )}
      style={pubStyle}
    >
      <div ref={pubRef} data-m32-dynamic-ad data-options={pubOptions}></div>
    </div>
  );
};

export default memo(Pub);

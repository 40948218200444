import { PubFormats } from "@/types/components/pub";

export const PUBFORMATS: PubFormats = {
   // leaderboards
  leaderboard: {
    name: "leaderboard",
    sizes: [
      [728, 90],
    ],
    kv: { pos: "top" },
  },
  leaderboardMobile: {
    name: "leaderboard-mobile",
    sizes: [
      [320, 50],
    ],
    kv: { pos: "top" },
  },
  leaderboardBox: {
    name: "leaderboard-box",
    sizes: [
      [300, 250],
      [728, 90],
    ],
    sizeMapping: [[[300, 250]], [[728, 90]]],
  },

  // mixed
  leaderboardBillboard: {
    name: "leaderboard-billboard",
    sizes: [
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [[300, 250]],
      [
        [728, 90],
        [970, 250],
      ],
    ],
  },
  leaderboardBillboardBig: {
    name: "leaderboard-billboard-big",
    sizes: [
      [300, 600],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [[300, 600]],
      [
        [728, 90],
        [970, 250],
      ],
    ],
  },
  leaderboardBillboardDouble: {
    name: "leaderboard-billboard-double",
    sizes: [
      [300, 250],
      [300, 600],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [300, 250],
        [300, 600],
      ],
      [
        [728, 90],
        [970, 250],
      ],
    ],
  },

  // boxes
  box: {
    name: "box",
    sizes: [[300, 250]],
  },
  boxDouble: {
    name: "box-double",
    sizes: [
      [300, 250],
      [300, 600],
    ],
  },
};
